import { preventEvent } from '../../utils/etc';

export class SocialLinks {
  constructor(wrapper, config) {
    this.role = null;
    this.config = config;
    this.facebookLink = wrapper.querySelector('[data-role="facebook-url"]');
    this.googleLink = wrapper.querySelector('[data-role="google-url"]');
    this.appleLink = wrapper.querySelector('[data-role="apple-url"]');

    this.facebookLink.addEventListener('click', this.facebookClickHandler.bind(this));
    this.googleLink.addEventListener('click', this.googleClickHandler.bind(this));
    this.appleLink.addEventListener('click', this.appleClickHandler.bind(this));
  }

  setRole(role) {
    this.role = role;
  }

  generateLinks(socialLink) {
    window.location.href = socialLink.replace(/___role___/i, this.role);
  }

  facebookClickHandler(event) {
    preventEvent(event);
    this.generateLinks(this.config.urls.facebookSocialUrl);
  }

  googleClickHandler(event) {
    preventEvent(event);
    this.generateLinks(this.config.urls.googleSocialUrl);
  }

  appleClickHandler(event) {
    preventEvent(event);
    this.generateLinks(this.config.urls.appleSocialUrl);
  }
}
