import { preventEvent, serializeInputsToFormData } from '../../utils/etc';
import FormField from './field';
import IMask from 'imask';

export default class ComplateRegistrationForm {
  constructor(wrapper) {
    this.form = wrapper.querySelector('[data-role="complete-registration-form"]');
    this.formButton = wrapper.querySelector('[data-role="submit-confirm-registration"]');
    this.successBlock = wrapper.querySelector('[data-role="success-change-email-block"]');
    this.inputs = [];
    this.changeEmailField = wrapper.querySelector('[data-role="change-email-field"]');
    this.codeInput = wrapper.querySelector('[data-role="complete-code-input"]');
    this.phoneInput = wrapper.querySelector('[data-role="complete-phone-input"]');
    this.completeCodeWrapper = wrapper.querySelector('[data-role="complete-code-wrapper"]');
    this.errorsWrapper = null;
    this.initMasks();
  }

  initMasks() {
    new IMask(this.codeInput, {
      mask: /^[0-9]\d{0,5}$/,
    });
  }

  setPhone(phone) {
    this.phoneInput.value = phone;
  }

  getData() {
    return {
      code: this.codeInput.value,
      phone: this.phoneInput.value,
    };
  }

  getActionUrl() {
    return this.form.action;
  }

  startLoading() {
    requestAnimationFrame(() => {
      // this.form.style.display = 'none';
      this.formButton.disabled = true;
    });
  }

  showForm() {
    requestAnimationFrame(() => {
      this.form.style.display = '';
      this.formButton.disabled = false;
    });
  }

  showSuccess(data) {
    requestAnimationFrame(() => {
      $('#emailForm').collapse('hide');
      this.formButton.disabled = false;
      this.endRegisterTitleBlock.innerHTML = data.title;
      this.endRegisterDecrBlock.innerHTML = data.description;
    });
  }

  showError(data) {
    this.errorsWrapper = document.createElement('div');
    this.errorsWrapper.className = 'form-group__error-list';
    this.codeInput.parentElement.appendChild(this.errorsWrapper);

    const item = document.createElement('div');
    item.className = 'form-group__error';
    item.innerHTML = data.error;
    this.errorsWrapper.appendChild(item);
  }

  clearErrors() {
    if (this.errorsWrapper) {
      this.errorsWrapper.remove();
    }
  }
}
