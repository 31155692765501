import { preventEvent, serializeInputsToFormData } from '../../utils/etc';
import FormField from './field';
import { findAll } from '../../utils/dom';
import { EventBus } from '../event/event-bus';
import Event from '../event/event';
import { FormEvents } from './events-enum';

export class SimpleAjaxForm extends EventBus {
  constructor(wrapper, config) {
    super(false);

    this.clientValidation = false;
    this.form = wrapper.querySelector('[data-role="form"]');
    this.form.noValidate = true;
    this.preloader = wrapper.querySelector('[data-role="preloader"]');
    this.successBlock = wrapper.querySelector('[data-role="success-block"]');

    this.url = this.form.action;
    this.inputs = this.initInputs();

    this.config = config;

    this.form.addEventListener('submit', this.formSubmitHandler.bind(this));
    this.showForm();
  }

  setClientValidatorsMap(validatorsMap) {
    for (const input of this.inputs) {
      if (validatorsMap[input.name]) {
        input.setCustomValidator(validatorsMap[input.name]);
      }
    }
  }

  setUrl(url) {
    this.url = url;
  }

  initInputs() {
    const inputs = [];
    for (const fieldEl of findAll(this.form, '[data-role="field"]')) {
      inputs.push(new FormField(fieldEl));
    }

    return inputs;
  }



  validate(inputs) {
    return new Promise((resolve) => {
      Promise.all(inputs.map((field) => {
        field.clearErrors();

        return field.validate()
      })).
        then((results) => results.filter((result) => !!result)).
        then((validResults) => {
          resolve(validResults.length === inputs.length);
        });
    });
  }

  formSubmitHandler(event) {
    event && preventEvent(event);

    return Promise.resolve(true).then(() => {
      if (!this.clientValidation) {
        return true;
      }

      return this.validate(this.inputs);
    }).then((isValid) => {
      if (!isValid) {
        return;
      }

      this.showPreloader();
      this.clearErrors();

      const formData = serializeInputsToFormData(this.inputs);
      return fetch(this.url, {
        credentials: 'include',
        body: this.config && this.config.payloadFilter ? this.config.payloadFilter(formData) : formData,
        method: 'POST',
      }).then((response) => {
        response.json().then((json) => {
          if (response.ok) {
            this.showSuccessBlock();

            this.dispatchSuccess();

          } else {
            this.setErrors(json.errors);
            this.showForm();

            this.dispatchFail();
          }
        }).catch((err) => {
          console.error(err);
          this.showForm();

          this.dispatchFail();
        });
      }).catch((err) => {
        console.error(err);
        this.showForm();

        this.dispatchFail();
      });
    });
  }

  showForm() {
    requestAnimationFrame(() => {
      this.form.style.display = '';
      this.preloader.style.display = 'none';
      this.successBlock.style.display = 'none';
    });
  }

  showPreloader() {
    requestAnimationFrame(() => {
      this.form.style.display = 'none';
      this.preloader.style.display = 'flex';
      this.successBlock.style.display = 'none';
    });
  }

  showSuccessBlock() {
    requestAnimationFrame(() => {
      this.form.style.display = 'none';
      this.preloader.style.display = 'none';
      this.successBlock.style.display = '';
    });
  }

  clearErrors() {
    for (const field of this.inputs) {
      field.clearErrors();
    }
  }

  setErrors(errors) {
    for (const field of this.inputs) {
      const error = errors[field.name];
      if (!error) {
        continue;
      }

      field.addError(error);
    }
  }

  dispatchSuccess() {
    const event = new Event(FormEvents.SUCCESS, this);
    event.extra.set('form', this);
    this.dispatch(FormEvents.SUCCESS, event);
  }

  dispatchFail() {
    const event = new Event(FormEvents.FAILED, this);
    event.extra.set('form', this);
    this.dispatch(FormEvents.FAILED, event);
  }
}

export default (context = document) => {
  const forms = Array.from(context.querySelectorAll('[data-component="simple-ajax-form"]'));
  for (const form of forms) {
    new SimpleAjaxForm(form);
  }
}