import { preventEvent } from '../../utils/etc';
import { SimpleAjaxForm } from '../../components/form/simple-ajax-form';
import isEmail from 'validator/es/lib/isEmail';
import IMask from 'imask';
import { findAll } from '../../utils/dom';
import ComplateRegistrationForm from '../../components/form/complate-registration-form';

export class RegisterForm {
  constructor(wrapper) {
    this.resendPhoneLimit = window.__moni.const.resendEmaiolLimit;
    const checkRecaptchaPath = window.__moni.urls.checkRecaptcha;
    this.resendPhoneCounter = 0;
    this.registerForm = new SimpleAjaxForm(wrapper);
    this.registerForm.clientValidation = true;
    this.complateRegistrationForm = new ComplateRegistrationForm(wrapper);
    this.registerBtn = wrapper.querySelector('[data-role="register-btn"]');
    this.registerTermsAgreed = wrapper.querySelector('[data-role="register-terms-agreed"]');
    this.changePhoneBtn = wrapper.querySelector('[data-role="change-email"]');
    this.phoneInput = wrapper.querySelector('[data-role="create-user-phone-input"]');
    this.passwordInput = wrapper.querySelector('[data-role="create-user-password-input"]');
    this.changePhoneInput = wrapper.querySelector('[data-role="change-phone-hidden-input"]');
    this.changePhoneNewPhoneInput = wrapper.querySelector('[data-role="change-phone-new-input"]');
    this.changePhonePasswordInput = wrapper.querySelector('[data-role="change-phone-password-input"]');
    this.endRegisterDecrBlock = wrapper.querySelector('[data-role="register-desc-block"]');
    this.endRegisterTitleBlock = wrapper.querySelector('[data-role="register-title-block"]');
    this.endRegisterDecrBlock = wrapper.querySelector('[data-role="register-desc-block"]');
    this.initMasks();
    this.complateRegistrationForm.form.addEventListener('submit', this.confirmRegistrationSubmitHandler.bind(this));
    this.registerForm.setClientValidatorsMap(this.getCustomValidatorsMap());

    window.grecaptcha &&
      (window.onReCaptchaSubmit = async token => {
        const formData = new FormData();
        formData.append('token', token);
        const response = await fetch(checkRecaptchaPath, {
          method: 'POST',
          credentials: 'include',
          body: formData,
        });

        window.grecaptcha.reset();

        if (response.ok) {
          this.registerForm.formSubmitHandler();
        }
      });
  }

  validate(inputs) {
    return new Promise(resolve => {
      Promise.all(
        inputs.map(field => {
          field.clearErrors();

          return field.validate();
        }),
      )
        .then(results => results.filter(result => !!result))
        .then(validResults => {
          resolve(validResults.length === inputs.length);
        });
    });
  }

  confirmRegistrationSubmitHandler(event) {
    preventEvent(event);
    this.complateRegistrationForm.startLoading();
    this.complateRegistrationForm.clearErrors();

    fetch(this.complateRegistrationForm.getActionUrl(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(this.complateRegistrationForm.getData()),
    })
      .then(response => {
        return response
          .json()
          .then(json => {
            if (response.ok) {
              window.location.href = window.__moni.urls.cabinetSettings;
            } else {
              // Restore grecaptcha is ID = 1
              grecaptcha.reset(1);
              json.error && this.complateRegistrationForm.showError(json);
              this.complateRegistrationForm.showForm();
            }
          })
          .catch(err => {
            err.error && this.complateRegistrationForm.showError(err);
            this.complateRegistrationForm.showForm();
          });
      })
      .catch(err => {
        err.error && this.complateRegistrationForm.showError(err);
        this.complateRegistrationForm.showForm();
      });
  }

  initMasks() {
    const PhoneInputs = findAll(document.body, '[data-role="create-user-phone-input"]:not([data-init])');
    var phoneBeginning = '+380';
    var phoneMask = {
      mask: '{+38\\0}000000000',
    };

    PhoneInputs.forEach(function (phoneInput) {
      var cellularPhone = IMask(phoneInput, phoneMask);
      phoneInput.addEventListener('focus', function () {
        if (cellularPhone.value === '') {
          cellularPhone.value = phoneBeginning;
        }
      });
    });
  }

  handleResponseErrors({ errors }) {
    this.registerForm.setErrors(errors);
    this.registerForm.showForm();
  }

  get isForceShow() {
    const url = new URL(document.location.href);
    const showModal = url.searchParams.get('showModal');

    return showModal === window.__moni.const.showModal.TYPE_REGISTER;
  }

  showForm(registerForm) {
    document.body.style.width = '100%';
    document.body.style.overflow = 'hidden';

    const modal = document.querySelector('#login-register-side-modal');
    modal && modal.classList.add('active');

    registerForm && registerForm.classList.add('active');
  }

  getCustomValidatorsMap() {
    const prepareValue = ({ value }) => `${value}`;
    const messages = window.__moni.translations.validation.register;

    return {
      phone: async field => {
        const value = prepareValue(field);
        if (value.length === 0 || value.length < 13) {
          field.addError(messages.email.empty);
          return Promise.resolve(false);
        }

        const data = new FormData();
        data.append('phone', value);
        this.registerBtn.disabled = true;
        this.registerBtn.classList.add('js-wait');

        const result = await fetch(window.__moni.urls.checkUniqueUser, {
          method: 'POST',
          credentials: 'include',
          body: data,
        })
          .then(response => response.json())
          .catch(() => {
            return new Promise.resolve(false);
          })
          .finally(() => {
            this.registerBtn.disabled = false;
            this.registerTermsAgreed.disabled = false;
            this.registerTermsAgreed.checked = false;
            this.registerBtn.classList.remove('js-wait');
            this.complateRegistrationForm.setPhone(value);
          });

        if (result && result.exists) {
          field.addError(messages.phone.duplicate);
          return Promise.resolve(false);
        }

        return new Promise(resolve => {
          this.registerBtn.disabled = true;
          this.registerBtn.classList.add('js-wait');

          const data = new FormData();
          data.append('phone', value);

          fetch(window.__moni.urls.checkSMSSendTimeout, {
            method: 'POST',
            credentials: 'include',
            body: data,
          })
            .then(response => response.json())
            .then(({ canSend, timeoutTime }) => {
              if (!canSend) {
                field.addError(messages.phone.smsSendTimeout.replace('%time%', timeoutTime));
              }

              resolve(canSend);
            })
            .catch(() => {
              resolve(false);
            })
            .finally(() => {
              this.registerBtn.disabled = false;
              this.registerTermsAgreed.disabled = false;
              this.registerTermsAgreed.checked = false;
              this.registerBtn.classList.remove('js-wait');
              this.complateRegistrationForm.setPhone(value);
            });
        });
      },
      password: field => {
        const value = prepareValue(field);
        if (value.length === 0 || value.length > 72) {
          field.addError(messages.password.length);
          return Promise.resolve(false);
        } else if (!/^[A-Za-z0-9=!*@\\|\-\_#&^,./"'()%$]{4,72}$/gi.test(value)) {
          field.addError(messages.password.length);
          return Promise.resolve(false);
        }

        return Promise.resolve(true);
      },
      termsAgreed: field => {
        field.disabled = false;
        if (!field.checked) {
          field.addError(messages.agree.empty);
          return Promise.resolve(false);
        }

        field.disabled = true;
        return Promise.resolve(true);
      },
    };
  }
}
