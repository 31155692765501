import { getSimpleFormStateMutator, preventEvent } from '../../utils/etc';
import IMask from 'imask';

export class RecoverPasswordForm {
  constructor() {
    const form = document.querySelector('[data-component="recover-password-form"]');
    form.noValidate = true;
    const restoreDataInput = form.querySelector('[data-role="restore-data-input"]');
    const restoreDataType = form.querySelector('[data-role="restore-type"]');
    const restoreGRecaptchaResp = form.querySelector('[data-role="restore-g-recaptcha-resp"]');
    const submitBtn = form.querySelector('[data-role="submit-btn"]');
    const errorBlock = form.querySelector('[data-role="error-block"]');
    this.errorBlockEl = errorBlock;
    const setResultState = getSimpleFormStateMutator(submitBtn, errorBlock);

    const recoveryForm = document.querySelector('[data-component="recovery-password-form"]');
    const recoveryCode = recoveryForm.querySelector('[data-role="recovery-code"]');
    this.recoveryCode = recoveryCode;
    const recoveryPassword = recoveryForm.querySelector('[name="password"]');
    const recoveryConfirmPassword = recoveryForm.querySelector('[name="confirm-password"]');
    const recoverySubmitBtn = recoveryForm.querySelector('[data-role="submit-btn"]');
    const codeErrorBlock = recoveryForm.querySelector('[data-role="code-error-block"]');
    const passwordErrorBlock = recoveryForm.querySelector('[data-role="password-error-block"]');
    const confirmPasswordErrorBlock = recoveryForm.querySelector('[data-role="confirm-password-error-block"]');
    this.codeErrorBlock = codeErrorBlock;
    this.passwordErrorBlock = passwordErrorBlock;
    this.confirmPasswordErrorBlock = confirmPasswordErrorBlock;
    const setResultCodeState = getSimpleFormStateMutator(recoverySubmitBtn, codeErrorBlock);
    const setResultPasswordState = getSimpleFormStateMutator(recoverySubmitBtn, passwordErrorBlock);
    const setResultConfirmPasswordState = getSimpleFormStateMutator(recoverySubmitBtn, confirmPasswordErrorBlock);

    const messages = window.__moni.translations.validation.recoveryPassword;
    const restoreDataIputMask = new IMask(restoreDataInput, {
      mask: '{+38\\0}000000000',
    });

    restoreDataType.addEventListener('change', function () {
      if (restoreDataType.value == 'phone') {
        restoreDataIputMask.updateOptions({ mask: '{+38\\0}000000000' });
      } else {
        restoreDataIputMask.updateOptions({ mask: /^\S*@?\S*$/ });
      }
    });

    form.addEventListener('submit', event => {
      preventEvent(event);

      setResultState('', true);
      this.errorBlockEl.classList.remove('active');
      const formData = new FormData();
      formData.append('type', restoreDataType.value);
      formData.append('data', restoreDataInput.value);
      formData.append('gRecaptchaRestoreResponse', restoreGRecaptchaResp.value);

      fetch(form.action, {
        method: 'POST',
        credentials: 'include',
        body: formData,
      })
        .then(response => {
          return response
            .json()
            .then(json => {
              if (response.ok) {
                $('.recovery-password-block').addClass('active').siblings().removeClass('active');
              } else {
                // Restore grecaptcha is ID = 2
                grecaptcha.reset(2);
                restoreButton.disabled = true;
                this.errorBlockEl.classList.add('active');
                setResultState(json.errors && json.errors.data ? json.errors.data : json.error, false);
              }
            })
            .catch(err => {
              this.errorBlockEl.classList.remove('active');
              setResultState(err, false);
            });
        })
        .catch(err => {
          this.errorBlockEl.classList.remove('active');
          setResultState(err, false);
        });
    });

    recoveryForm.addEventListener('submit', event => {
      preventEvent(event);

      const formIsValid = recoveryFormValidations();
      if (!formIsValid) {
        return false;
      }

      setResultState('', true);
      this.errorBlockEl.classList.remove('active');
      fetch(recoveryForm.action, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
          type: restoreDataType.value,
          data: restoreDataInput.value,
          code: recoveryCode.value,
          password: recoveryConfirmPassword.value,
        }),
      })
        .then(response => {
          return response
            .json()
            .then(json => {
              if (response.ok) {
                notificationAlert('success', messages.notifyTitle, messages.notifyMessage);
                const modal = document.querySelector('#login-register-side-modal');
                modal && modal.classList.remove('active');
              } else {
                if (json.errors && json.errors.code) {
                  this.codeErrorBlock.classList.add('active');
                  setResultCodeState(json.errors.code, false);
                }
                if (json.errors && json.errors.password) {
                  this.confirmPasswordErrorBlock.classList.add('active');
                  setResultConfirmPasswordState(json.errors.password, false);
                }
                if (json.error) {
                  this.confirmPasswordErrorBlock.classList.add('active');
                  setResultConfirmPasswordState(json.error, false);
                }
              }
            })
            .catch(err => {
              this.confirmPasswordErrorBlock.classList.remove('active');
              setResultConfirmPasswordState(err, false);
            });
        })
        .catch(err => {
          this.confirmPasswordErrorBlock.classList.remove('active');
          setResultConfirmPasswordState(err, false);
        });
    });

    function recoveryFormValidations() {
      let isValid = true;
      codeErrorBlock.classList.remove('active');
      setResultCodeState('', false);
      passwordErrorBlock.classList.remove('active');
      setResultPasswordState('', false);
      confirmPasswordErrorBlock.classList.remove('active');
      setResultConfirmPasswordState('', false);

      if (recoveryCode.value.length === 0 || recoveryCode.value.length > 6 || recoveryCode.value.length < 6) {
        codeErrorBlock.classList.add('active');
        setResultCodeState(messages.lengthError, false);
        isValid = false;
      }
      if (recoveryPassword.value.length === 0 || recoveryPassword.value.length < 4) {
        passwordErrorBlock.classList.add('active');
        setResultPasswordState(messages.minLengthError, false);
        isValid = false;
      }
      if (recoveryConfirmPassword.value.length === 0 || recoveryConfirmPassword.value.length < 4) {
        confirmPasswordErrorBlock.classList.add('active');
        setResultConfirmPasswordState(messages.minLengthError, false);
        isValid = false;
      }
      if (
        recoveryPassword.value.length >= 4 &&
        recoveryConfirmPassword.value.length >= 3 &&
        recoveryPassword.value !== recoveryConfirmPassword.value
      ) {
        confirmPasswordErrorBlock.classList.add('active');
        setResultConfirmPasswordState(messages.differentPasswordError, false);
        isValid = false;
      }

      return isValid;
    }

    this.initMasks();
  }

  initMasks() {
    new IMask(this.recoveryCode, {
      mask: /^[0-9]\d{0,5}$/,
    });
  }
}
