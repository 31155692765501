import 'babel-polyfill';
import { RegisterForm } from './register';
import {LoginForm} from './login';
import {SocialLinks} from './socialLinks';
import {RecoverPasswordForm} from './recover-password';
import {GenericModal} from "../../components/modal/generic-modal";
import {forceShowModal} from "../../utils/url";

const initMap = {
    registerForm: {
        selector: '[data-role="register-form"]',
        viewModel: RegisterForm,
    },
    loginForm: {
        selector: '[data-component="login-form"]',
        viewModel: LoginForm,
    },
    recoverPasswordForm: {
        selector: '[data-component="recover-password-form"]',
        viewModel: RecoverPasswordForm,
    },
};

class Auth {
    constructor() {
        this.config = window.__moni.pages.auth;
        this.roleTitle = document.querySelector('[data-marker="role-title"]');
        const roleModalTriggers = document.querySelectorAll('[data-role="role-select"]');
        this.roleInput = document.querySelector('[data-role="role-input"]');
        const roleModalWrapper = document.querySelector('[data-role="register-role"]');

        this.roleModal = new GenericModal(roleModalWrapper);
        this.roleTriggerClickHandler = this.roleTriggerClickHandler.bind(this);
        for (const trigger of [...roleModalTriggers]) {
            trigger.addEventListener('click', this.roleTriggerClickHandler);
        }

        this.initComponents();
        this.initSocialLinks();
        this.registerHash();
    }

    roleTriggerClickHandler(event) {
        this.roleModal.open().then((result) => {
            if (result && result !== 'close') {
                this.roleInput.value = result;
                requestAnimationFrame(() => this.roleTitle.innerHTML = this.config.translations[result]);
                this.socialComponent.setRole(result);
                return result;
            }
        });
    }

    registerHash() {
        switch (forceShowModal()) {
            case window.__moni.const.showModal.TYPE_REGISTER:
                this.roleTriggerClickHandler();
                break;
        }
    }

    initComponents() {
        for (const key of Object.keys(initMap)) {
            const wrapper = document.querySelector(initMap[key]['selector']);
            if (!wrapper) {
                continue;
            }

            new initMap[key]['viewModel'](wrapper, this.config[key] || {});
        }
    }

    initSocialLinks() {
        const socialWrapper = document.querySelector('[data-component="social-url"]');
        this.socialComponent = new SocialLinks(socialWrapper, {urls: this.config.socialsUrls});
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new Auth();
});