import { preventEvent, getSimpleFormStateMutator } from '../../utils/etc';
import { listenElement } from '../../utils/dom';
import IMask from 'imask';

export class LoginForm {
  constructor() {
    this.allowPlainSend = false;
    this.form = document.querySelector('[data-component="login-form"]');
    this.form.noValidate = true;
    const registerBtn = document.querySelector('.header-dropdown-nav__register-btn');
    const submitBtn = this.form.querySelector('[data-role="submit-btn"]');
    const errorBlock = this.form.querySelector('[data-role="error-block"]');
    this.errorBlockEl = errorBlock;
    this.setResultState = getSimpleFormStateMutator(submitBtn, errorBlock);
    this.loginInput = this.form.querySelector('[data-role="login-input"]');
    this.passwordInput = this.form.querySelector('[data-role="password-input"]');
    this.initMaskInput();

    listenElement(document.body, '[data-role="register-btn-dup"]', 'click', event => {
      preventEvent(event);

      registerBtn && registerBtn.click();
    });

    this.form.addEventListener('submit', this.formSubmitHandler.bind(this));
  }

  initMaskInput() {
    // IMask(this.loginInput, {
    //   mask: [
    //     {
    //       mask: '{+38\\0}000000000',
    //     },
    //     {
    //       mask: /^\S*@?\S*$/,
    //     },
    //   ],
    // });
  }

  formSubmitHandler(event) {
    if (this.allowPlainSend) {
      return;
    }

    preventEvent(event);

    this.setResultState('', true);
    this.errorBlockEl.classList.remove('active');
    const formData = new FormData();
    formData.append('username', this.loginInput.value);
    formData.append('password', this.passwordInput.value);

    fetch(this.form.dataset.action, {
      method: 'POST',
      credentials: 'include',
      body: formData,
    })
      .then(response => {
        if (response.ok) {
          this.form.removeEventListener('submit', this.formSubmitHandler.bind(this));
          this.allowPlainSend = true;
          this.form.submit();
          return {
            error: '',
          };
        } else {
          return response.json();
        }
      })
      .then(({ error }) => {
        if (error.length) this.errorBlockEl.classList.add('active');
        this.setResultState(error, false);
      })
      .catch(() => {
        this.errorBlockEl.classList.remove('active');
        this.setResultState('', false);
      });
  }
}
