export default class FormField {
  constructor(wrapper) {
    this.wrapper = wrapper;
    this.input = wrapper.querySelector('[name]');
    if (wrapper.dataset.validator && !this.input.dataset.validator) {
      this.input.dataset.validator = wrapper.dataset.validator;
    }
    this._errorWrapper = null;
    this.customValidator = null;
  }

  get checked() {
    return this.input.checked;
  }

  get name() {
    return this.input.name;
  }

  get type() {
    return this.input.type;
  }

  get value() {
    return this.input.value;
  }

  get files() {
    return this.input.files;
  }

  get disabled() {
    return this.input.disabled;
  }

  set disabled(value) {
    this.input.disabled = !!value;
  }

  get dataset() {
    return this.input.dataset;
  }

  show() {
    requestAnimationFrame(() => {
      this.wrapper.style.display = '';
    });
  }

  hide() {
    requestAnimationFrame(() => {
      this.wrapper.style.display = 'none';
    });
  }

  get errorWrapper() {
    if (!this._errorWrapper) {
      this._errorWrapper = this.generateErrorWrapper();

      requestAnimationFrame(() => {
        this.wrapper.appendChild(this._errorWrapper);
      });
    }

    return this._errorWrapper;
  }

  setCustomValidator(validator) {
    this.customValidator = validator;
  }

  generateErrorWrapper() {
    let wrapper = this.wrapper.querySelector('[data-role="field-error-wrapper"]');
    if (wrapper) {
      return wrapper;
    }
    wrapper = document.createElement('div');
    wrapper.className = 'form-group__error-list';
    wrapper.dataset.role = 'field-error-wrapper';
    wrapper.dataset.cy = 'error-wrapper';
    return wrapper;
  }

  addError(text) {
    this.wrapper.classList.add('invalid');
    requestAnimationFrame(() => {
      const item = document.createElement('div');
      item.className = 'form-group__error';
      item.innerHTML = text;

      this.errorWrapper.appendChild(item);
    });
  }

  clearErrors() {
    this.wrapper.classList.remove('invalid');
    requestAnimationFrame(() => {
      this.errorWrapper.innerHTML = '';
    });
  }

  validate() {
    if (this.customValidator) {
      return this.customValidator(this);
    }

    return this.input.validity.valid;
  }

  showBrowserValidationMessage() {
    this.input.reportValidity();
  }
}
