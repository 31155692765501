import {getObjectLength} from './etc';

export const buildParams = (params, queryKey = '') => {
  let paramsArg = [];

  for (const key in params) {
    if (!params.hasOwnProperty(key)) {
      continue;
    }

    const paramKey = queryKey.length > 0 ? `${queryKey}[${key}]` : key;

    paramsArg.push(
      `${paramKey}=${encodeURIComponent(params[key] ? params[key] : '')}`);
  }

  return paramsArg.join('&');
};

export const getQueryParam = (key) => {
  const url = new URL(document.location.href);
  return url.searchParams.get(key);
};

export const forceShowModal = () => {
  return getQueryParam('showModal');
}

export const buildQuery = (url, params, queryKey = '') => {
  if (getObjectLength(params) < 1) {
    return url;
  }

  const paramsStr = buildParams(params, queryKey);

  return /.+\?.+/gis.test(url) ?
    `${url}&${paramsStr}` :
    `${url}?${paramsStr}`;
};
