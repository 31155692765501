import {preventEvent} from '../../utils/etc';
import { findAll } from '../../utils/dom';

export class GenericModal {
  constructor(wrapper) {
    this._resolver = null;
    this._backdrop = null;
    this.actionHandlers = new Map();
    this.value = {};
    this.wrapper = wrapper;
    this.inputs = findAll(wrapper, '[data-value]');
    this.content = wrapper.querySelector('[data-role="modal-content"]')

    this.actionClickHandler = this.actionClickHandler.bind(this);
    this.valueInputHandler = this.valueInputHandler.bind(this);

    const actions = findAll(wrapper, '[data-action]');
    for (const action of actions) {
      action.addEventListener('click', this.actionClickHandler);
    }

    for (const input of this.inputs) {
      input.addEventListener('input', this.valueInputHandler);
    }

    this.wrapper.addEventListener('click', preventEvent);
  }

  setContent(fragment) {
    if (!this.content) {
      return;
    }

    requestAnimationFrame(() => {
      this.content.innerHTML = '';
      this.content.appendChild(fragment);
    });
  }
  
  setRawContent(raw) {
    if (!this.content) {
      return;
    }
    
    requestAnimationFrame(() => {
      this.content.innerHTML = raw;
    });
  }

  resolveAction (action) {
    this._resolver && this._resolver(action);
    this._resolver = null;
  }

  open() {
    return new Promise((resolve) => {
      this._resolver = resolve;

      this.wrapper.classList.add('show');
      document.body.classList.add('modal-opened');

      requestAnimationFrame(() => {
        document.body.appendChild(this.getBackdrop());
        this.wrapper.style.display = 'block';
      });
    });
  }

  close() {
    this.resolveAction(null);

    this.wrapper.classList.remove('show');
    document.body.classList.remove('modal-opened');

    for (const input of this.inputs) {
      input.value = '';
    }

    requestAnimationFrame(() => {
      document.body.removeChild(this.getBackdrop());
      this.wrapper.style.display = '';
    });
  }

  getBackdrop() {
    if (!this._backdrop) {
      this._backdrop = document.createElement('div');
      this._backdrop.className = 'modal-backdrop fade show';
    }

    return this._backdrop;
  }

  addActionHandler(action, handler) {
    let handlers = this.actionHandlers.get(action);
    if (!handlers) {
      handlers = [];
      this.actionHandlers.set(action, handlers);
    }

    handlers.push(handler);

    return handlers.length - 1;
  }

  removeActionHandler(action, key) {
    let handlers = this.actionHandlers.get(action);
    if (handlers) {
      this.actionHandlers.set(action, handlers.splice(key));
    }
  }

  removeAllHandlers(action) {
    this.actionHandlers.delete(action);
  }

  actionClickHandler(event) {
    preventEvent(event);

    const {action} = event.target.dataset;

    const handlers = this.actionHandlers.get(action) || [];
    for (const handler of handlers) {
      handler(this);
    }

    this.resolveAction(action);
    this.close();
  }

  valueInputHandler({target}) {
    this.value[target.dataset.value] = target.value;
  }

  getValue() {
    return this.value;
  }
}
