import Event from './event';

export class EventBus {
  constructor(isGlobal = true) {
    this.EVENT_DOM_ADDED = 'dom_added';
    this.bus = new Map();

    isGlobal &&
      document.addEventListener('DOMContentLoaded', () => {
        window.__moni.utils.eventBus = this;
        window.__moni.utils.event = Event;
      });
  }

  subscribe(type, callback) {
    if (typeof callback !== 'function') {
      return;
    }

    let callbackArray = this.bus.get(type);
    if (!callbackArray) {
      callbackArray = [];
      this.bus.set(type, callbackArray);
    }

    callbackArray.push(callback);
  }

  dispatch(type, event) {
    if (!(event instanceof Event)) {
      console.error(event, 'must be instance of event');

      return false;
    }

    const listeners = this.bus.get(type) || [];
    if (!listeners.length) {
      return false;
    }

    for (const listener of listeners) {
      listener(event);
    }

    return true;
  }

  trigger(type, sender, wrapper) {
    const event = new Event(type, sender);
    event.extra.set('wrapper', wrapper);
    this.dispatch(type, event);
  }

  unsubscribe(type, callback) {
    const listeners = this.bus.get(type) || [];
    if (!listeners.length) {
      return false;
    }
    this.bus.set(
      type,
      listeners.filter(handler => handler !== callback),
    );
  }
}

export default new EventBus();
